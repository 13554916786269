/// <reference path='jquery-2.2.4.min.js' />
/// <reference path='jquery.nicescroll.min.js' />
/// <reference path='vegas.min.js' />
/// <reference path='owl.carousel.min.js' />
/// <reference path='jquery.stellar.min.js' />
/// <reference path='jquery.matchHeight-min.js' />
/// <reference path='jquery.fs.boxer.min.js' />
/// <reference path='bootstrap.js' />

'use strict';

var $window = $(window),
	nStartScreen = document.getElementById('start-screen');

/* smartresize
================================================== */
(function($,sr){

	// debouncing function from John Hann
	// http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
	var debounce = function (func, threshold, execAsap) {
		var timeout;

		return function debounced () {
			var obj = this, args = arguments;
			function delayed () {
					if (!execAsap)
							func.apply(obj, args);
					timeout = null;
			};

			if (timeout)
					clearTimeout(timeout);
			else if (execAsap)
					func.apply(obj, args);

			timeout = setTimeout(delayed, threshold || 100);
		};
	}
	// smartresize 
	jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };
})(jQuery,'smartresize');

/* custom scroll
================================================== */
function _custom_scroll ()
{
	if ( device.desktop() )
	{
		$("html").niceScroll({
			cursorcolor:"#555",
			mousescrollstep: 50,
			scrollspeed: 70,
			zindex: 4,
			cursorborderradius: 0,
			cursorborder:0,
			horizrailenabled: false,
			cursorfixedheight:120,
			cursorwidth:"10px",
			disablemutationobserver: true
		});
	};
};

/* main menu
================================================== */
function _main_menu ()
{
	var nTopBar      = document.getElementById('top-bar'),
		nMenuToggler = document.getElementById('top-bar__navigation-toggler'),
		nNav         = document.getElementById('top-bar__navigation'),

		jTopBar      = $(nTopBar),
		jMenuToggler = $(nMenuToggler),
		jNav         = $(nNav),

		jLink        = jNav.find('li a'),
		iTop         = $(nStartScreen).innerHeight() - 80;

	window.onscroll = function() {
		if ( (window.pageYOffset || document.documentElement.scrollTop) >= iTop ) {

			jTopBar.addClass('fixed in');

		} else if ( jTopBar.hasClass('fixed') ) {

			jTopBar.removeClass('in').addClass('out');

			setTimeout(function(){
				jTopBar.removeClass('fixed out');
			}, 100 );

		};
	};

	jLink.on('touchend click', function (e) {
		e.preventDefault();

		if ( location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname ) {

			var target = $(this.hash);

			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');

			if ( target.length ) {
				$('html,body').stop().animate({
					scrollTop: target.offset().top - 80
				}, 1000);
			}
		}

		if ( jMenuToggler.is(':visible') ) {

			jTopBar.removeClass('expanded');
			jMenuToggler.removeClass('active');
		}

		return false;
	});

	jMenuToggler.on('touchend click', function (e) {
		e.preventDefault();

		var $this = $(this);

		$this.toggleClass('active');
		jTopBar.toggleClass('expanded');

		return false;
	});

	$window.smartresize(function() {
		if ( $(this).width() > 991 ) {

			jMenuToggler.removeClass('active');
			jTopBar.removeClass('expanded');
		}
	});
};

/* home slider
================================================== */
function _home_slider ()
{
	var slider = $(nStartScreen).children(".start-screen__slider");

	if ( slider.length > 0 ) {

		var slides = [
				{
					name: "img 1",
					src: 'img/home_img/1.jpg'
				},
				{
					name: "img 2",
					src: 'img/home_img/2.jpg'
				},
				{
					name: "img 3",
					src: 'img/home_img/3.jpg'
				}
			],
			slider_content = $('.start-screen__content'),
			dots, a, x;

		slider.vegas({
			timer: false,
			transition: [ 'fade', 'zoomOut', 'blur', 'swirlLeft', 'swirlRight' ],
			transitionDuration: 4000,
			delay: 5000,
			slides: slides,
			overlay: 'img/home_img/overlays/03.png',
			init: function (globalSettings) {
				if ( this.data('dots') == true ) {

					var $this = this,
						dots = $('<nav class="vegas-dots"></nav>');

					$this.find('.vegas-control').append(dots);

					for (var i = 0; i < slides.length; i++) {
						x = $('<a "href="#" class="paginatorLink"></a>');

						x.on('click', function(e) {
							e.preventDefault();

							$this.vegas('jump', dots.find('a').index(this));
						});

						dots.append(x);
					};

					a = dots.find('a');
					a.eq(0).addClass('active');

					slider_content.eq(0).addClass('active');
				};
			},
			play: function (index, slideSettings) {
			},
			walk: function (index, slideSettings) {

				if ( this.data('dots') == true ) {

					a.removeClass('active').eq(index).addClass('active');
				};

				slider_content.removeClass('active').eq(index).addClass('active');
			}
		});
	};
};

/* owl carousel
================================================== */
function _owl_carousel ()
{
	var slider_1 = $('.feedback-slider--style-1'),
		slider_2 = $('.feedback-slider--style-2');

	if ( slider_1.length > 0 ) {

		slider_1.children('.owl-carousel').owlCarousel({
			loop: true,
			nav: false,
			dots: true,
			autoplay: true,
			autoplayTimeout: 3000,
			autoplayHoverPause: true,
			autoHeight: true,
			smartSpeed: 1000,
			margin: 30,
			navText: [
				'<i class="fa fa-angle-left"></i>',
				'<i class="fa fa-angle-right"></i>'
			],
			responsive: {
				0:{
					items:1
				},
				992:{
					items:2
				}
			}
		});
	}

	if ( slider_2.length > 0 ) {

		slider_2.children('.owl-carousel').owlCarousel({
			loop: true,
			nav: false,
			dots: true,
			autoplay: true,
			autoplayTimeout: 3000,
			autoplayHoverPause: true,
			autoHeight: true,
			smartSpeed: 1000,
			margin: 30,
			navText: [
				'<i class="fa fa-angle-left"></i>',
				'<i class="fa fa-angle-right"></i>'
			],
			responsive: {
				0:{
					items:1
				},
				992:{
					items:1
				}
			}
		});
	}
}

/* google map
================================================== */
function _g_map ()
{
	var maps = $('.g_map');

	if ( maps.length > 0 ) {

		maps.each(function() {
			var current_map = $(this);
			var latlng = new google.maps.LatLng(current_map.attr('data-longitude'), current_map.attr('data-latitude'));
			var point = current_map.attr('data-marker');

			var myOptions = {
				zoom: 14,
				center: latlng,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				mapTypeControl: false,
				scrollwheel: false,
				draggable: true,
				panControl: false,
				zoomControl: false,
				disableDefaultUI: true
			};

			var stylez = [
				{
					featureType: "all",
					elementType: "all",
					stylers: [
						{ saturation: -100 } // <-- THIS
					]
				}
			];

			var map = new google.maps.Map(current_map[0], myOptions);

			var mapType = new google.maps.StyledMapType(stylez, { name:"Grayscale" });
			map.mapTypes.set('Grayscale', mapType);
			map.setMapTypeId('Grayscale');

			var marker = new google.maps.Marker({
				map: map,
				icon: {
					size: new google.maps.Size(66,56),
					origin: new google.maps.Point(0,0),
					anchor: new google.maps.Point(20,56),
					url: point
				},
				position: latlng
			});

			google.maps.event.addDomListener(window, "resize", function() {
				var center = map.getCenter();
				google.maps.event.trigger(map, "resize");
				map.setCenter(center);
			});
		});
	}
};

/* parallax
================================================== */
function _parallax ()
{
	if ( device.desktop() )
	{
		$.stellar({
			scrollProperty: 'scroll',
			verticalOffset: 0,
			horizontalOffset: 0,
			horizontalScrolling: false
		});

		$window.smartresize(function() {
			$.stellar('refresh');
		});
	};
};

/* scroll to top
================================================== */
function _scrollTop ()
{
	var	nBtnToTopWrap = document.getElementById('btn-to-top-wrap'),
		jBtnToTopWrap = $(nBtnToTopWrap);

	if ( jBtnToTopWrap.length > 0 ) {

		var nBtnToTop = document.getElementById('btn-to-top'),
			jBtnToTop = $(nBtnToTop);

		jBtnToTop.on('click', function (e) {
			e.preventDefault();

			$('body,html').stop().animate({ scrollTop: 0 } , 1500);

			return false;
		});

		$window.on('scroll', function(e) {

			if ( $window.scrollTop() > jBtnToTop.data('visible-offset') ) {

				if ( jBtnToTopWrap.is(":hidden") ) {
					jBtnToTopWrap.fadeIn();
				};

			} else {

				if ( jBtnToTopWrap.is(":visible") ) {
					jBtnToTopWrap.fadeOut();
				};
			};
		});
	};
};

/* boxer gall
================================================== */
function _gall ()
{
	var galleryElement = $("a[data-gallery]");

	if ( galleryElement.length > 0 ) {
		galleryElement.boxer({
			fixed: true,
			videoWidth: 800
		});
	}
};

/* equal height
================================================== */
function _equal_height ()
{
	$('.matchHeight-container').each(function() {
		$(this).find('.matchHeight-item').matchHeight({
			byRow: true,
			property: 'height'
		});
	});
};

$(document).ready(function() {

	/* custom scroll
	================================================== */
	_custom_scroll();

	/* main menu
	================================================== */
	_main_menu();

	/* home slider
	================================================== */
	_home_slider();

	/* owl carousel
	================================================== */
	_owl_carousel();

	/* google map
	================================================== */
	_g_map();

	/* parallax
	================================================== */
	_parallax();

	/* scroll to top
	================================================== */
	_scrollTop();

	/* boxer gall
	================================================== */
	_gall();

	/* equal height
	================================================== */
	_equal_height();
});